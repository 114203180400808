jQuery(function($) {
	'use strict';

	$('.p-news').each(function(i, e) {

		var shiftNum = 0;
		var timer;

		var list  = $(e).find('.p-news__list');
		var items = $(e).find('.p-news__item');
		var next  = $(e).find('.p-news__next');

		var move = function() {
			var move = (shiftNum * list.width() * -1);
			list.css('transform', 'translateX(' + move + 'px)');
		}

		var moveUp = function() {
			shiftNum ++;

			if (shiftNum >= items.length) {
				shiftNum = 0;
			}

			move();
		}

		// var autoMove = function() {
		// 	timer = setTimeout(function() {
		// 		moveUp();
		// 		autoMove();
		// 	}, 3000);
		// }

		$(window).resize(function() {
			clearTimeout(timer);
			shiftNum = 0;
			move();
			//autoMove();
		});

		next.click(function() {
			clearTimeout(timer);
			moveUp();
			//autoMove();
		});

		//autoMove();
	});
});
