

jQuery(function($) {
	'use strict';

	var productPage = $('.p-product-page');
	var main        = productPage.find('.p-product-page__main');
	var aside       = productPage.find('.p-product-page__aside');

	var asidePositioning = function() {
		if ('fixed' !== aside.css('position')) {
			aside.css('marginTop', '');
			return;
		}

		if (main.outerHeight() <= aside.outerHeight()) {
			aside.css('position', 'static');
			return;
		} else {
			aside.css('position', '');
		}

		var _asideClone = aside.clone().css('marginTop', '').css('transition', 'none').css('visivility', 'hidden');
		aside.after(_asideClone);
		var productPageEndY     = productPage.offset().top + productPage.outerHeight();
		var asideEndY           = _asideClone.offset().top + _asideClone.outerHeight();
		_asideClone.remove();

		if (asideEndY >= productPageEndY) {
			aside.css('marginTop', productPageEndY - asideEndY);
		} else {
			aside.css('marginTop', '');
		}
	};

	asidePositioning();
	$(window).scroll(function() {
		asidePositioning();
	});
	$(window).resize(function() {
		asidePositioning();
	});
});
