jQuery(function($) {
	'use strict';

	var min = 768;
	var startWindowSize = $(window).outerWidth();

	/**
	 * 初期化処理
	 */
	function init() {
		if (min <= $(window).outerWidth()) {
			// PC
			openToggleContainer($('.p-release-calendar__toggle-btn'));
		} else {
			// スマホ
			closeToggleContainer($('.p-release-calendar__toggle-btn'));
		}
		$('.p-release-calendar__body').data('shift-count', 0);
		$('.p-release-calendar__body').css('transform', '');
		$('.p-release-calendar__shift-btn').attr('aria-hidden', 'false');
		$('.p-release-calendar__unshift-btn').attr('aria-hidden', 'false');
	}

	init();
	$(window).resize(function() {
		// スマホはスクロールによってアドレスバーがきえることでもリサイズイベントが発生するので
		// PC => スマホ、スマホ => PC は init するけどスマホ => スマホは init させない
		if ($(window).outerWidth() !== startWindowSize) {
			init();
		}
		startWindowSize = $(window).outerWidth();
	});

	/**
	 * スマホ用 - もっとみるで隠れた container を開く
	 */
	function openToggleContainer(btnWrapper) {
		btnWrapper.each(function(i, e) {
			var btn       = $(e).find('span');
			var container = $('#' + btn.attr('aria-controls'));

			$(e).attr('aria-hidden', 'true');
			$(e).attr('aria-expanded', 'true');
			container.attr('aria-hidden', 'false');
		});
	}

	/**
	 * スマホ用 - container を隠す
	 */
	function closeToggleContainer(btnWrapper) {
		btnWrapper.each(function(i, e) {
			var btn       = $(e).find('span');
			var container = $('#' + btn.attr('aria-controls'));

			$(e).attr('aria-hidden', 'false');
			$(e).attr('aria-expanded', 'false');
			container.attr('aria-hidden', 'trie');
		});
	}

	/**
	 * スマホ用 - トグルボタンをクリックしたとき
	 */
	$('.p-release-calendar__toggle-btn').each(function(i, e) {
		var btnWrapper = $(e);
		btnWrapper.find('span').click(function() {
			openToggleContainer(btnWrapper);
		});
	});

	/**
	 * PC用 - 右に1月分スライド
	 */
	function shift(shiftBtn) {
		var btns = shiftBtn.parent();
		var body = $('#' + shiftBtn.attr('aria-controls'));

		shiftBtn.attr('aria-hidden', 'true');

		body.data('shift-count', body.data('shift-count') + 1);
		var shiftCount = body.data('shift-count');

		var translateX = (body.find('.p-release-calendar__col').outerWidth()) * -1 * shiftCount;
		body.css(
			'transform',
			'translateX(' + translateX + 'px)'
		);
	}

	/**
	 * PC用 - 左に1月分スライド
	 */
	function unshift(unshiftBtn) {
		var btns = unshiftBtn.parent();
		var body = $('#' + unshiftBtn.attr('aria-controls'));
		var shiftBtn = body.find('.p-release-calendar__shift-btn');

		shiftBtn.attr('aria-hidden', 'false');

		body.data('shift-count', body.data('shift-count') - 1);
		var shiftCount = body.data('shift-count');

		var translateX = (body.find('.p-release-calendar__col').outerWidth()) * -1 * shiftCount;
		body.css(
			'transform',
			'translateX(' + translateX + 'px)'
		);
	}

	/**
	 * PC用 - もっとみる（shift-btn）をクリックしたとき
	 */
	$('.p-release-calendar__shift-btn').each(function(i, e) {
		var shiftBtn = $(e);
		shiftBtn.click(function() {
			shift(shiftBtn);
		});
	});

	/**
	 * PC用 - 前月分をみる（unshift-btn）をクリックしたとき
	 */
	$('.p-release-calendar__unshift-btn').each(function(i, e) {
		var unshiftBtn = $(e);
		unshiftBtn.click(function() {
			unshift(unshiftBtn);
		});
	});
});
