jQuery(function($) {
	'use strict';

	popup(
		$('.c-product-share-buttons__item--twitter > a'),
		'Share on Twitter',
		550,
		400
	);

	popup(
		$('.c-product-share-buttons__item--line > a'),
		'Send to LINE',
		670,
		530
	);

	function popup(target, title, width, height) {
		target.on('click', function(event) {
			event.preventDefault();
			window.open(
				target.attr('href'),
				title,
				'width=' + width + ', height=' + height + ', menubar=no, toolbar=no, scrollbars=yes'
			);
		});
	}
});
