jQuery(function($) {
	'use strict';

	var header  = $('.site-header');
	var wrapper = $('.p-entry__content .woocommerce');
	var aside   = wrapper.find('.woocommerce-MyAccount-navigation');

	if (! wrapper.length || ! aside.length) {
		return;
	}

	var _get_aside_max_width = function() {
		var position = aside.css('position');
		aside.css('position', '');
		var width = aside.css('width');
		aside.css('position', position);
		return width;
	}

	var _get_aside_position_left = function() {
		var position = aside.css('position');
		aside.css('position', '');
		var left = aside.offset().left;
		aside.css('position', position);
		return left;
	}

	var asidePositioning = function() {
		var min = 1377;

		if (min > $(window).outerWidth()) {
			aside.css('position', '');
			aside.css('max-width', '');
			return;
		}

		var headerEndY = header.offset().top + header.outerHeight();

		if (headerEndY >= wrapper.offset().top) {
			aside.css('left', _get_aside_position_left());
			aside.css('top', header.position().top + header.outerHeight());
			aside.css('max-width', _get_aside_max_width());
			aside.css('position', 'fixed');
		} else {
			aside.css('position', '');
		}
	};

	asidePositioning();
	$(window).scroll(function() {
		asidePositioning();
	});
	$(window).resize(function() {
		asidePositioning();
	});
});
