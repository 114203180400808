jQuery(function($) {
	"use strict";

	var btns = $('.site-header .c-hamburger-btn');

	btns.each(function(i, e) {
		var btn    = $(e);
		var target = $('#' + btn.attr('aria-controls'));

		btn.click(function() {
			if (target.length) {
				if ('true' === btn.attr('aria-expanded')) {
					btn.attr('aria-expanded', 'false');
					target.attr('aria-hidden', 'true');
				} else {
					btn.attr('aria-expanded', 'true');
					target.attr('aria-hidden', 'false');
				}
			}
		});
	});
});
