jQuery(function($) {
	'use strict';

	var adminbar    = $('#wpadminbar');
	var header      = $('.site-header');
	var content     = $('.site-content');
	var min         = 599;
	var fixAdminbar = function() {
		if (0 >= adminbar.length) {
			return;
		}
		if (min > $(window).outerWidth()) {
			var scroll = $(window).scrollTop();
			if (scroll >= adminbar.outerHeight()) {
				_stickyHeaderForSmallSize();
			} else {
				_staticHeaderForSmallSize();
			}
		} else {
			_fixedHeaderForLargeSize();
		}
	};

	function _stickyHeaderForSmallSize() {
		header.css('position', '');
		header.css('top', 0);
		content.css('padding-top', '');
	}

	function _staticHeaderForSmallSize() {
		header.css('position', 'relative');
		header.css('top', '');
		content.css('padding-top', 0);
	}

	function _fixedHeaderForLargeSize() {
		header.css('position', '');
		header.css('top', '');
		content.css('padding-top', '');
	}

	fixAdminbar();
	$(window).resize(function() {
		fixAdminbar();
	});
	$(window).scroll(function() {
		fixAdminbar();
	});
});
