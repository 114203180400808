jQuery(function($) {
	'use strict';

	var max   = 767;
	var start = 0;
	var cart  = $('.p-sticky-cart');

	$(window).resize(function() {
		if (max >= $(window).outerWidth()) {
			return;
		}

		cart.removeClass('p-sticky-cart--down');
	});

	$(window).scroll(function() {
		if (max < $(window).outerWidth()) {
			return;
		}

		var current = $(window).scrollTop();

		if (0 === current) {
			cart.removeClass('p-sticky-cart--down');
		} else if (start > current) {
			// 上スクロール
		} else {
			// 下スクロール
			cart.addClass('p-sticky-cart--down');
		}

		start = current;
	});
});
