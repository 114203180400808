jQuery(function($) {
	'use strict';

	var panel = $('.p-age-authentication');

	if ('true' === window.Cookies.get('isOver18')) {
		panel.attr('aria-hidden', true);
	} else {
		panel.find('.p-age-authentication__inner').attr('aria-hidden', false);
	}

	panel.find('.p-age-authentication__btn').click(function() {
		var isOver18 = $(this).attr('data-over18');
		if ('true' === isOver18) {
			window.Cookies.set('isOver18', 'true');
			panel.attr('aria-hidden', true);
		} else {
			window.Cookies.set('isOver18', 'false');
			window.location.href = 'https://www.wani.com/';
		}
	});

	panel.on('touchstart, touchend, touchmove', function(event) {
		event.preventDefault();
	});
});
