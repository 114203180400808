jQuery(function($) {
	'use strict';

	var sideNav = $('#secondary');
	var btn     = $('[aria-controls="' + sideNav.attr('id') + '"]');
	var content = $('.site-content');
    var toggle      = $(".p-side-nav__item .toggle");

	$(window).resize(function() {
		sideNav.attr('aria-hidden', 'false');
		btn.attr('aria-expanded', 'true');
		content.attr('data-is-expanded-sidebar', 'true');
	});

	btn.click(function() {
		if ('true' === btn.attr('aria-expanded')) {
			content.attr('data-is-expanded-sidebar', 'true');
		} else {
			content.attr('data-is-expanded-sidebar', 'false');
		}
	});

    toggle.click(function() {
        $(this).toggleClass("toggle-active").next().slideToggle(300);
    });
});
