jQuery(function($) {
	'use strict';

	$.fn.waniEmergency = function(args) {
		var input    = [];
		var konami   = [16, 16, 16];
		var namco    = [38, 38, 38];
		var imgCache = [];
		var config   = $.extend({
			src   : 'src',
			srcset: 'srcset',
			target: '',
		}, args);

		var method = {
			init: function() {
				$.each(config.target, function(i, e) {
					$(document).find(e).each(function() {
						imgCache.push({
							'src'   : $(this).attr(config.src),
							'srcset': $(this).attr(config.srcset)
						});
					});
				});
			},

			push: function() {
				$.each(config.target, function(i, e) {
					$(document).find(e).each(function(i) {
						var rand = Math.floor( Math.random() * 30 );
						var wani = '/wp-content/themes/wanitheme/assets/img/wani/' + rand + '.jpg';
						$(this).attr(config.src, wani);
						if ( $(this).attr(config.srcset) ) {
							$(this).attr(config.srcset, wani);
						}
					});
					input = [];
				});
			},

			pull: function() {
				$.each(config.target, function(i, e) {
					$(document).find(e).each(function(i) {
						$(this).attr(config.src, imgCache[i].src);
						$(this).attr(config.srcset, imgCache[i].srcset);
					});
				});
			},

			checkCode: function(codeArray, _target) {
				return codeArray.join('-').indexOf(_target.join('-')) >= 0;
			}
		};

		method.init();

		$('#wanicon-emergency').click(function() {
			method.push();
		});

		$(window).keyup(function(e) {
			input.push(e.keyCode);

			if (method.checkCode(input, konami)) {
				method.push();
			}

			if (method.checkCode(input, namco)) {
				method.pull();
			}
		});
	};

	$(document).waniEmergency({
		target: [
			'.p-entry__content img:not(".p-product-page__share img")',
			'.p-main-visual img',
			'.p-products img:not(".p-product-page__share img")'
		]
	});

	$(document).waniEmergency({
		target: [
			'.p-main-visual .c-caroucel__item'
		],
		src: 'data-src'
	});

	//$('.p-entry__content img:not(".p-product-page__share img")').waniEmergency();
	//$('.p-main-visual img').waniEmergency();
	//$('.p-main-visual .c-caroucel__item').waniEmergency({src: 'data-src'});
	//$('.p-products img:not(".p-product-page__share img")').waniEmergency();
});
