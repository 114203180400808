jQuery(function($) {
	'use strict';

	var mySwipers = [];

	// var _destroySwiper = function(Swiper) {
	// 	if (null !== Swiper && typeof Swiper !== 'undefined') {
	// 		Swiper.destroy(true, true);
	// 		$('.c-caroucel__next-btn, .c-caroucel__prev-btn').addClass('swiper-button-disabled');
	// 		return true;
	// 	}
	// }

	var initSwiper = function() {
		$('.c-caroucel').each(function(i, e) {
			// if (_destroySwiper(mySwipers[i])) {
			// 	mySwipers.splice(i, 1);
			// }

			if ($(e).attr('data-cols') || ($(e).attr('data-cols-tablet') && 768 <= $(window).outerWidth())) {
				mySwipers[i] = new Swiper($(e).find('.c-caroucel__border'), {
					effect                : "slide",
					loop                  : false,
					slidesPerView         : 'auto',
					nextButton            : $(e).find('.c-caroucel__next-btn'),
					prevButton            : $(e).find('.c-caroucel__prev-btn'),
					containerModifierClass: 'c-caroucel__border',
					wrapperClass          : 'c-caroucel__container',
					slideClass            : 'c-caroucel__item'
				});
			} else {
				// if (_destroySwiper(mySwipers[i])) {
				// 	mySwipers.splice(i, 1);
				// }
			}
		});
	};

	initSwiper();

	$(window).resize(function() {
		initSwiper();
	});
});
