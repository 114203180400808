jQuery(function($) {
	'use strict';

	var min    = 1392;
	var header = $('.site-header');
	var start  = 0;

	$(window).resize(function() {
		if (min > $(window).outerWidth()) {
			return;
		}

		header.removeClass('site-header-down');
		header.removeClass('site-header-up');
	});

	$(window).scroll(function() {
		if (min <= $(window).outerWidth()) {
			return;
		}

		var current = $(window).scrollTop();

		if (0 === current) {
			header.removeClass('site-header-down');
			header.removeClass('site-header-up');
		} else if (start > current) {
			// 上スクロール
			header.removeClass('site-header-down');
			header.addClass('site-header-up');
		} else {
			// 下スクロール
			header.removeClass('site-header-up');
			header.addClass('site-header-down');
		}

		start = current;
	});
});

jQuery(function($) {
	'use strict';

	var min    = 1392;
	var header = $('.asp_w');
	var start  = 0;

	$(window).resize(function() {
		if (min > $(window).outerWidth()) {
			return;
		}

		header.removeClass('site-header-down');
		header.removeClass('site-header-up');
	});

	$(window).scroll(function() {
		if (min <= $(window).outerWidth()) {
			return;
		}

		var current = $(window).scrollTop();

		if (0 === current) {
			header.removeClass('site-header-down');
			header.removeClass('site-header-up');
		} else if (start > current) {
			// 上スクロール
			header.removeClass('site-header-down');
			header.addClass('site-header-up');
		} else {
			// 下スクロール
			header.removeClass('site-header-up');
			header.addClass('site-header-down');
		}

		start = current;
	});
});


