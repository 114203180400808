jQuery(function($) {
	'use strict';

	var commentsarea = $('#comments');
	var btn = $('.p-product-page__comments-toggle-btn span');

	commentsarea.each(function(i, e) {
		var comments = $(e).find('.comment');

		btn.click(function() {
			$(this).remove();
			comments.addClass('comment--show');
		});
	});
});
