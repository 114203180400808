function pushEmptyCells() {
	'use strict';

	jQuery(function($) {
		var list  = $('.p-products__list');
		var items = list.find('.p-products__item');

		list.find('.p-products__item--is-empty').remove();

		var cellsCount = Math.round(list.outerWidth() / items.eq(0).outerWidth());
		if (0 === (items.length % cellsCount)) {
			var shortrageCellsCount = 0;
		} else {
			var shortrageCellsCount = cellsCount - (items.length % cellsCount);
		}

		var emptyCells = [];
		for (var i = 0; i < shortrageCellsCount; i++) {
			emptyCells.push($('<li>', { class: 'p-products__item p-products__item--is-empty' }));
		}
		list.append(emptyCells);
	});
}

jQuery(function($) {
	'use strict';
	pushEmptyCells();
	$(window).resize(function() {
		pushEmptyCells();
	});
});
