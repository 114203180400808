jQuery(function($) {
	"use strict";

	var drawer      = $('.p-drawer');
	var btn         = $('[aria-controls="' + drawer.attr('id') + '"]');
	var closeBtn    = drawer.find('.p-drawer__close');
	var mypageBtn   = drawer.find('.p-drawer__mypages > span');
	var windowWidth = $(window).width();
    var toggle      = $(".p-drawer__item .toggle");

	$(window).resize(function() {
		if (windowWidth !== $(window).width()) {
			drawer.attr('aria-hidden', 'true');
			btn.attr('aria-expanded', 'false');
		}
		windowWidth = $(window).width();
	});

	closeBtn.click(function() {
		drawer.attr('aria-hidden', true);
		btn.attr('aria-expanded', false);
	});

	mypageBtn.click(function() {
		var target = $('#' + $(this).attr('aria-controls'));
		if ('false' === target.attr('aria-hidden')) {
			mypageBtn.attr('aria-expanded', false);
			target.attr('aria-hidden', true);
		} else {
			mypageBtn.attr('aria-expanded', true);
			target.attr('aria-hidden', false);
		}
	});

    toggle.click(function() {
        $(this).toggleClass("toggle-active").next().slideToggle(300);
    });

});
