//= include ./_is-integer.js

jQuery(function($) {
	'use strict';

	$('.p-main-visual').each(function(i, e) {
		var mainvisual = $(e);
		var container  = mainvisual.find('.p-main-visual__container');
		var controller = mainvisual.find('.p-main-visual__controller');

		controller.find('.p-main-visual__control-btn').click(function() {
			var href = $(this).attr('data-href');
			var src  = $(this).attr('data-src');

			container.prepend('<img class="p-main-visual__overlay" alt="">');
			var overlay = container.find('.p-main-visual__overlay');
			overlay.attr('src', src);

			setTimeout(function() {
				container.find('a').attr('href', href);
				container.find('img').attr('src', src);
			}, 300);

			// Safari だと一瞬 src の書き換えが遅れるので対応
			setTimeout(function() {
				overlay.remove();
			}, 320);
		});
	});
});
